@import '../styles/Mixin.scss';

.review_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px;
  height: 200px;
  border-radius: 5px;
  background: rgba(white, 70%);
  box-shadow: 0 3rem 2rem rgb(0 0 0 / 10%);

  @include phone {
    padding: 10px;
  }

  p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-align: left;
    font-weight: 00;
    font-style: italic;
    font-size: 1.6rem;
    line-height: 18px;
    -webkit-line-clamp: 8;
  }

  h3 {
    text-align: right;
    font-weight: 600;
    font-size: 1.6rem;
  }
}

:global {

  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: rgba($color-secondary_01, 0.8);
    font-size: 30px;
  }
}