@import '../styles/Mixin.scss';

.wrapper {
  position: relative;
  padding: 30px 0;

  &::before {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: calc(100% - 60px);
    background-image: url(../images/about.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    filter: saturate(0.4);
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  min-height: 400px;
}

.subsection {
  margin: 30px auto;
  padding: 30px;
  max-width: 600px;
  background: rgba($color-secondary_03, 0.7);
  text-align: center;
  border-radius: 10px;

  @include phone {
    max-width: 400px;
  }

  h2 {
    letter-spacing: 3px;
    font-size: 5rem;
    font-family: 'Love Ya Like A Sister';
  }

  span {
    display: block;
    margin-bottom: 20px;
    color: $color-primary_dark;
    font-size: 3.1rem;
    font-family: 'Markazi Text';
  }

  p {
    margin: 0 auto;
    width: 70%;
    color: $color-primary_dark;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 21px;

    @include phone {
      width: 100%;
    }
  }

  .opentime {
    margin: 20px auto;
    width: 70%;

    h3 {
      font-size: 2rem;
      font-weight: 600;
      line-height: 26px;
      padding-bottom: 1rem;
      border-bottom: 1px solid #999;
    }

    li {
      font-size: 1.6rem;
      line-height: 2.5rem;

      strong {
        display: block;
        margin-top: 5px;
        font-weight: 600;
      }
    }
  }
}