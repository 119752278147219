@import '../../styles/Mixin.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  @include flexCenter;
  width: 100%;
  height: 100%;
  background: rgba($color-primary_dark, 0.8);
}

.content {
  position: relative;
  padding: 30px;
  width: 60%;
  background: $color-secondary_03;

  @include phone {
    width: 80%;
  }

  @include small {
    padding: 10px;
    width: 100%;
  }
}

.close_tag {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 20px;
  transition: all 1s ease;

  &:hover path,
  &:active path {
    transition: all .1s ease;
    stroke: $color-secondary_01;
  }
}

.booking_content {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @include small {
    gap: 10px;
  }

  .input_area {
    flex-basis: 50%;
  }

  .input_item {
    position: relative;
    margin-bottom: 20px;
  }

  .invalid_msg {
    position: absolute;
    top: calc(100% + 3px);
    right: 0;
    left: 0;
    color: $color-warning;
    font-size: 1.2rem;
  }

  input,
  textarea {
    border: 1px solid rgb(204, 204, 204);
    @include input_block;

    // form invalid
    &:hover {
      border: 1px solid rgb(170, 170, 170);
    }

    &.invalid {
      border-color: $color-warning;

      &:focus-visible {
        outline: none;
        box-shadow: $warning-shadow;
      }
    }
  }

  .confirm_btn {
    border-color: transparent;
    background: $color-primary_02;
    transition: all .2s ease;

    &:hover {
      border-color: transparent;
      background: $color-primary-01;
      color: $color-secondary_03;
    }

    &:active {
      transform: translateY(2px);
    }

    &.disable {
      background: rgb(170, 170, 170);
      color: $color-secondary_03;
    }
  }

  .booking_info {
    flex-basis: 50%;

    h3 {
      margin-bottom: 10px;
      text-align: left;
      font-size: 2rem;
    }

    >div:not(:last-child) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
      text-align: left;
      gap: 10px;
    }

    >div:last-child {
      margin-top: 10px;
      border-top: 1px solid $color-primary_dark;
    }

    span {
      font-weight: 600;
      font-size: 1.6rem;
    }

    svg {
      font-size: 18px;
    }

    .booking_notes {
      padding: 10px;
      text-align: left;
      font-size: 1.2rem;

      li {
        list-style: inside;
        list-style-type: disc;
        line-height: 15px;
      }
    }
  }
}