@import '../styles/Mixin.scss';

.wrapper {
  padding: 120px 0 60px 0;
  background: #fafafb;

  @include table_port {
    padding: 60px 0 30px 0;
  }

  @include phone {
    padding: 30px 0;
  }

}

.title {
  margin-bottom: 60px;
  @include flexSBcenter;

  @include table_port {
    margin-bottom: 20px;
  }
}

.cards {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include phone {
    padding: 0 25px;
  }

  @include small {
    flex-direction: column;
    padding: 0;
    gap: 10px;
  }
}