$color-primary_01: #495e57;
$color-primary_02: #F4CE14;
$color-secondary_01: #EE9972;
$color-secondary_02: #ffc692;
$color-secondary_03: #EDEFEE;
$color-primary_dark: #333333;
$color-warning: #dc3545;
$warning-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);

@mixin warning-shadow {
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}


// media query manager ($breakpoint arguement)
// - phone
// - tab-port
// - tab-land
// 1em = 16px

// @mixin breakpoint($breakpoint) {
//   @if $breakpoint == phone {
//     @media (max-width: 37.5em) {
//       @content
//     }
//   }

//    @ if $breakpoint ==tab-port {
//      @media (max-width: 56.25em) {
//        @content
//      }
//    }

//    @if $breakpoint ==tab-land {
//      @media (max-width: 75em) {
//        @content
//      }
//    }
//  }

@mixin small {
  @media only screen and (max-width:500px) {
    @content;
  }
}


@mixin phone {
  @media only screen and (max-width:768px) {
    @content;
  }
}

@mixin table_port {
  @media only screen and (max-width:900px) {
    @content;
  }
}

@mixin flexSBcenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

// input style
@mixin input_block {
  border: 1px solid $color-primary_dark;
  width: 100%;
  padding: 10px 20px;
  font-size: 1.4rem;
  font-family: 'Karla';
  border-radius: 4px;
  background: transparent;
  transition: border .2s ease;

  &:hover {
    border-color: $color-primary_02;
  }
}