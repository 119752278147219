@import '../styles/Mixin.scss';

.wrapper {
  position: relative;
  padding: 100px 0;
  text-align: center;

  @include phone {
    padding: 50px 0 80px 0;
  }

  h2 {
    margin-bottom: 60px;
    letter-spacing: 2px;
    font-size: 6rem;
    color: $color-primary_dark;

    @include phone {
      margin-bottom: 30px;
    }
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 60%), url('https://images.unsplash.com/photo-1534650075489-3baecec1e8b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
  background-position: center;
  background-size: cover;
  filter: blur(4px);
}