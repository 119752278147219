@import "../../styles/Mixin.scss";

.wrapper {
  padding: 60px 0;
  min-height: calc(100vh - 374px);

  h2 {
    margin-bottom: 10px;
  }
}

.booking_info {
  padding: 10px 0 40px;

  p {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 1.64rem;
  }
}
