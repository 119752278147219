@import '../../styles/Mixin.scss';

header {
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  background: white;
  box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  gap: 10px;

  @include phone {
    height: 75px;
  }
}

.logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;

  @include table_port {
    // width: 150px;
  }

  @include phone {
    width: 150px;
  }
}

.nav_menu {
  ul {
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 100px;
    gap: 5px;

    @include table_port {
      position: fixed;
      top: 0;
      left: -110%;
      z-index: 10;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      width: 100vw;
      height: 100vh;
      background: $color-secondary_02;
      transition: left .2s ease-in;

      &.active {
        left: 0;
      }
    }

    li {
      position: relative;
      font-weight: 500;
      font-size: 2rem;
      font-family: 'Karla', sans-serif;
      line-height: 23px;
      cursor: pointer;

      &:not(:last-child)::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 2px solid transparent;
        content: "";
        transition: transform .2s ease-out;
        transform: scale(0, 1);
      }

      &.actived_link,
      &:hover::before {
        border-bottom: 2px solid $color-secondary_01;
        transform: scale(1, 1);
      }

      &:last-child {
        margin-left: 10px;
        border: 1px solid;
        transition: all .3s ease-out;

        &:hover {
          border-color: $color-secondary_02;
          background: $color-secondary_02 ;
        }
      }

      @include table_port {
        color: $color-primary_dark;
        font-weight: 600;

        &:last-child {
          margin-left: 0px;
        }
      }
    }
  }

  a {
    display: inline-block;
    padding: 10px;
  }
}

.hamburger {
  z-index: 100;
  display: none;
  cursor: pointer;

  @include table_port {
    display: block;
  }
}