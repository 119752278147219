@import '../../styles/Mixin.scss';

.card {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  width: 32%;
  border-radius: 10px;
  background: rgba($color-secondary_02, 0.3);
  transition: transform .2s ease-in;

  &:hover {
    transform: translateY(-1rem) scale(1.03);
  }

  @include small {
    flex-direction: row;
    width: 100%;
  }

  &_photo {
    overflow: hidden;
    height: 175px;

    @include small {
      min-width: 25%;
      width: 25%;
      height: unset;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 15px;

    @include phone {
      padding: 15px 10px;
    }

    @include small {
      justify-content: flex-start;
      padding: 10px;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 20px;

    @include phone {
      line-height: 16px;
    }
  }

  &_title {
    margin-bottom: 10px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include small {
      margin-bottom: 5px;
      min-height: 20px;
    }

    h3 {
      font-weight: 700;
      font-size: 1.8rem;
    }

    span {
      margin-top: 5px;
      color: $color-warning;
      font-size: 1.8rem;
    }
  }
}

.card_footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;

  p {
    font-weight: 700;
    font-size: 1.6rem;

    @include small {
      font-size: 1.4rem;
    }
  }
}