@import "../../styles/Mixin.scss";

.wrapper {
  padding: 60px 0;
  min-height: calc(100vh - 375px);

  @include phone {
    padding: 30px 0;
    min-height: calc(100vh - 346px);
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    text-align: center;
    gap: 20px;

    @include small {
      flex-direction: column-reverse;
    }
  }

  .img_container {
    flex-basis: 60%;
    max-width: 60%;

    @include small {
      max-width: none;
    }
  }

  .booking_content {
    max-width: 40%;

    @include small {
      max-width: 80%;
    }
  }

  h2 {
    margin-bottom: 20px;
  }

  input,
  select,
  .bookingBtn {
    @include input_block;
    padding: 10px 40px;
  }

  select {
    position: relative;
    appearance: none;
    color: $color-primary_dark;
  }

  .input_group {
    position: relative;
    margin-bottom: 10px;

    svg {
      position: absolute;
      top: 50%;
      left: 10px;
      font-size: 20px;
      transform: translateY(-50%);
    }
  }

  p {
    margin-bottom: 5px;
    text-align: left;
    font-size: 1.2rem;
    line-height: 16px;

    span {
      text-decoration: underline;
    }
  }

  .bookingBtn {
    position: relative;
    padding: 10px;
    color: $color-primary_dark;
    // background: transparent;
    // border: none;

    &:hover {
      border-color: transparent;
      background: $color-primary_02;
      transition: all 0.2s ease;
    }

    &:active {
      transform: scale(0.98);
    }
  }
}
