@import '../../styles/Mixin.scss';

.wrapper {
  background: #F3F3F4;
}

.footer_content {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: left;
  gap: 40px;

  @include small {
    gap: 1.5rem;
  }
}

.footer_logo {
  flex-basis: 20%;
  max-width: 100px;
  min-width: 45px;
}

.footer_group {
  display: flex;
  flex-direction: column;
  justify-content: center;

  li {
    margin-bottom: 10px;
    font-size: 1.6rem;
  }
}

.social_links {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.copyright {
  padding: 10px 0;
  background: $color-primary_01;
  color: $color-secondary_03;
  text-align: center;
  letter-spacing: 1px;
  font-size: 1.6rem;
  font-family: "Markazi Text";
}