@import '../styles/Mixin.scss';

.background {
  background: $color-primary_01;
}

.wrapper {
  padding-top: 3rem;
  padding-bottom: 5rem;
  max-height: 400px;
  @include flexSBcenter;

  @include phone {
    overflow: hidden;
    padding-bottom: 3rem;
  }

  @include small {
    flex-direction: column;
    max-height: none;
  }
}

.subsection {
  flex-basis: 60%;

  @include phone {
    width: 100%;

    h1 {
      font-size: 6rem;
    }

    p {
      margin: 15px 0;
      width: 90%;
    }
  }

  @include small {
    text-align: center;
  }

  h1 {
    color: $color-primary_02;
    letter-spacing: 2px;
    font-size: 6.4rem;
    font-family: 'Love Ya Like A Sister';

    @include phone {
      font-size: 5.5rem;
    }
  }

  span {
    display: block;
    color: $color-secondary_03;
    font-size: 4rem;
    font-family: 'Markazi Text';
  }

  p {
    margin: 30px 0 50px 0;
    width: 70%;
    color: $color-secondary_03;
    font-size: 1.8rem;
    line-height: 21px;

    @include small {
      width: 100%;
      margin: 20px 0;
    }
  }
}

.img_container {
  flex-basis: 40%;
  max-width: 350px;

  @include table_port {
    & img {
      margin-bottom: -70px;
    }
  }

  @include phone {
    margin-right: -70px;

    & img {
      margin-bottom: 0;
      width: 250px;
    }
  }

  @include small {
    margin-right: 0;
    margin-top: 30px;
  }

  img {
    margin-bottom: -130px;

    @include phone {
      margin-bottom: unset;
    }
  }
}